<template>
  <div class="rounded-md border border-gray-300">
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import UnderlineExtension from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { EditorContent, useEditor } from "@tiptap/vue-3";
import { onBeforeUnmount } from "vue";
export default {
  components: {
    EditorContent,
  },
  props: {
    content: {
      required: true,
    },
  },
  setup(props) {
    const editor = useEditor({
      content: props.content,
      editable: false,
      extensions: [StarterKit, UnderlineExtension],
    });

    onBeforeUnmount(() => {
      editor.value.destroy();
    });

    return {
      editor,
    };
  },
};
</script>
