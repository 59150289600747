<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        document.isTemplate ? t("headings.template") : t("headings.document")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div v-if="!waiting" class="card p-4">
        <h3 class="mb-3">
          {{ document.name }}
          <span class="text-sm font-normal">
            -
            {{
              t(
                `classifications.${getClassificationName(
                  document.classificationId,
                  classifications
                )}`
              )
            }}</span
          >
        </h3>
        <read-only
          v-if="document.content"
          :content="JSON.parse(document.content)"
        />
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-end">
          <form-button
            :command="() => router.push(`/documents/${document.id}/edit`)"
            :disabled="waiting || document.status !== 'open'"
            id="edit-button"
            label="edit"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
      <div v-if="!waiting" class="card p-4">
        <h3 class="mb-3">{{ t("headings.signatories") }}</h3>
        <custom-table
          v-if="document.signatories?.length"
          :fields="fields"
          :items="document.signatories"
          :loading="waiting"
          :pagination="true"
          :perPage="10"
          :searchable="true"
          :searchFields="searchFields"
          :showHeader="true"
          sortBy="email"
          :sortFields="sortFields"
        >
          <template #head(action)>
            <div class="flex w-full justify-end">
              <form-button
                :command="() => cancelSigning()"
                :disabled="!isCancellable(document.signatories)"
                id="signing-cancel-button"
                label="cancel_signing"
                textVariant="light"
                type="button"
                variant="danger"
              />
            </div>
          </template>
          <template #cell(status)="{ value }">
            {{ t(`status.${value}`) }}
          </template>
          <template #cell(signedAt)="{ value }">
            {{ getLocaleDate(locale, value?.seconds * 1000) }}
          </template>
          <template #cell(action)="{ item }">
            <div class="flex justify-end">
              <form-button
                :command="() => cancelSignatory(item.slug)"
                :icon="TrashIcon"
                id="signatory-cancel-button"
                label=""
                textVariant="light"
                type="button"
                variant="danger"
              />
            </div>
          </template>
        </custom-table>
        <hr class="my-8 border-gray-300" />
        <div>
          <div class="flex w-full flex-col gap-4 lg:w-2/3 xl:w-1/2">
            <div
              v-for="(_, sIndex) in signatories"
              :key="sIndex"
              class="flex gap-4"
            >
              <form-input
                class="w-full"
                id="signatory-input"
                :placeholder="t('labels.email')"
                type="email"
                v-model="signatories[sIndex]"
              />
              <form-button
                v-if="signatories.length > 1"
                :command="() => removeSignatory(sIndex)"
                :icon="TrashIcon"
                id="signatory-remove-button"
                label=""
                textVariant="light"
                type="button"
                variant="danger"
              />
            </div>
            <small v-if="signatoryError" class="text-rose-600">{{
              t(`form.errors.${signatoryError}`)
            }}</small>
            <div class="flex justify-end gap-2">
              <form-button
                :command="() => addBoardMembers()"
                :disabled="waiting"
                id="add-board-members-button"
                label="add_board_members"
                :textVariant="company.primaryText"
                type="button"
                :variant="company.primary"
              />
              <form-button
                :command="() => addManagers()"
                :disabled="waiting"
                id="add-managers-button"
                label="add_managers"
                :textVariant="company.primaryText"
                type="button"
                :variant="company.primary"
              />
              <form-button
                :command="() => addSignatory()"
                :disabled="waiting"
                id="add-signatory-button"
                label="add_signatory"
                :textVariant="company.primaryText"
                type="button"
                :variant="company.primary"
              />
            </div>
          </div>
        </div>
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-end">
          <form-button
            :command="() => sendForSigning()"
            :disabled="waiting"
            id="send-for-signing-button"
            label="send_for_signing"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import ReadOnly from "../components/editor/ReadOnly.vue";
import FormButton from "../components/form/FormButton.vue";
import FormInput from "../components/form/FormInput.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getClassificationName } from "../utils/classificationUtils";
import { getLocaleDate } from "../utils/dateUtils";
import validate from "../utils/validators";
export default {
  components: {
    Breadcrumb,
    CustomTable,
    FormButton,
    FormInput,
    ReadOnly,
    TrashIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const boardMembers = computed(() => store.state.user.boardMembers);
    const document = computed(() => store.state.document.document);
    const classifications = computed(
      () => store.state.document.classifications
    );
    const personnel = computed(() => store.state.user.personnel);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.document.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "email",
        label: "email",
      },
      {
        key: "status",
        label: "status",
      },
      {
        key: "signedAt",
        label: "date",
      },
      {
        key: "action",
        label: "empty",
      },
    ]);
    const searchFields = ref(["email"]);
    const sortFields = ref(["email"]);

    const signatories = ref([""]);
    const signatoryError = ref(null);

    const addBoardMembers = () => {
      store.dispatch("user/getBoardMembers", document.value.estateId);
    };

    const addManagers = () => {
      store.dispatch("user/getPersonnel", document.value.companyId);
    };

    const addSignatory = () => {
      signatories.value.push("");
    };

    const cancelSignatory = (slug) => {
      store.dispatch("document/cancelSignatory", {
        documentId: document.value.id,
        slug,
      });
    };

    const cancelSigning = () => {
      store.dispatch("document/cancelSigning", document.value.id);
    };

    const isCancellable = (signatories) => {
      for (const signatory of signatories) {
        if (signatory.status === "signed") {
          return false;
        }
      }
      return true;
    };

    const removeSignatory = (index) => {
      signatories.value.splice(index, 1);
    };

    const sendForSigning = async () => {
      for (const signatory of signatories.value) {
        signatoryError.value = await validate(
          ["isRequired", "isEmail"],
          signatory
        );
      }
      if (signatoryError.value) return;
      const data = {
        documentId: document.value.id,
        signatories: signatories.value,
      };
      store.dispatch("document/sendForSigning", data);
    };

    onMounted(() => {
      if (!document.value.id || document.value.id != route.params.documentId) {
        store.dispatch("document/getDocument", route.params.documentId);
      }
      if (!classifications.value.length) {
        store.dispatch("document/getClassifications");
      }
    });

    watch(
      () => boardMembers.value,
      (val) => {
        for (const boardMember of val) {
          if (!signatories.value.includes(boardMember.email)) {
            signatories.value.push(boardMember.email);
          }
        }
      }
    );

    watch(
      () => personnel.value,
      (val) => {
        for (const manager of val) {
          if (!signatories.value.includes(manager.email)) {
            signatories.value.push(manager.email);
          }
        }
      }
    );

    return {
      addBoardMembers,
      addManagers,
      addSignatory,
      cancelSigning,
      cancelSignatory,
      classifications,
      company: computed(() => store.state.company.company),
      document,
      fields,
      getClassificationName,
      getLocaleDate,
      isCancellable,
      locale,
      removeSignatory,
      router,
      searchFields,
      sendForSigning,
      signatories,
      signatoryError,
      sortFields,
      t,
      TrashIcon,
      waiting,
    };
  },
};
</script>
